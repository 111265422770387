import { GatsbyImage } from 'gatsby-plugin-image';

import AspectRatio from '@components/atoms/AspectRatio';
import Box from '@components/atoms/Box';
import { Overlay } from '@components/atoms/Overlay';

const Poster = ({ poster, posterAspectRatio }: { poster: any; posterAspectRatio: number }) => (
    <Box width="100%" height="100%" position="relative" overflow="hidden">
        <Overlay />
        <AspectRatio ratio={posterAspectRatio}>
            <GatsbyImage
                imgStyle={{ height: '100%' }}
                style={{ transform: 'scale(1.5)' }}
                alt={poster.alt}
                image={poster.asset.gatsbyImageData}
            />
        </AspectRatio>
    </Box>
);
export default Poster;
