import Poster from '@components/routes/Blog/Poster';
import Heading from '@components/routes/Blog/components/Heading';
import PostCategory from '@components/routes/Blog/components/PostCategory';
import PostPreview from '@components/routes/Blog/components/PostPreview';
import routeMap from '@utils/routeMap';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { animated } from 'react-spring';
import styled from 'styled-components';

import Box from '@components/atoms/Box';
import Card from '@components/atoms/Card';
import { CardSize } from '@components/atoms/Card/Card';
import Typography from '@components/atoms/Typography';

import ArrowRightButton from '@components/molecules/Icons/ArrowRightButton';

const StyledArrowBtn = styled(ArrowRightButton)`
    margin-left: 0.5rem;
    vertical-align: middle;
`;

const StyledLink = styled(animated(Link))`
    display: block;
    will-change: transform, width, height, opacity;
`;

const BlogCard = ({
    slug,
    layout,
    categories,
    title,
    postPreview,
    poster,
    size,
    posterAspectRatio,
    id,
}: any) => {
    const { t } = useTranslation();

    return (
        <StyledLink
            data-key={id}
            to={`${routeMap.info.blog}${slug?.current}`}
            style={{
                position: size === CardSize.large ? 'relative' : 'relative',
            }}
        >
            <Card cardSize={size} layout={layout} className="backdrop-blur-dark">
                <Poster poster={poster} posterAspectRatio={posterAspectRatio} />
                <Box p="15px 25px 40px" width="100%" height="100%">
                    <PostCategory category={categories[0]?.title} />
                    <Heading heading={title} />
                    <PostPreview postPreview={postPreview[0].children[0].text} />
                    <Typography variant="accent" color="var(--blue-brand)">
                        {t('ReadMore')} <StyledArrowBtn color="var(--blue-brand)" />
                    </Typography>
                </Box>
            </Card>
        </StyledLink>
    );
};

export default BlogCard;
