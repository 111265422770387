import List from '@components/routes/Blog/List';
import { graphql } from 'gatsby';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SEO from '@theme/SEO';

import BackgroundImage from '@components/atoms/BackgroundImage';
import Box from '@components/atoms/Box';
import Dropdown, { DropdownItemProps } from '@components/atoms/Dropdown';
import GridChild from '@components/atoms/GridChild';
import GridParent from '@components/atoms/GridParent';
import Typography from '@components/atoms/Typography';

import ArrowDown from '@components/molecules/Icons/ArrowDown';

import Layout from '@components/templates/Layout';

const ArrowIcon = (): ReactElement => <ArrowDown size="micro" color="inherit" />;

export const query = graphql`
    query blogPage($language: String = " ") {
        locales: allLocale(
            filter: { language: { eq: $language }, ns: { in: ["blog", "commons"] } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img: file(relativePath: { eq: "Faq/bg.jpg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        categories: allSanityCategory {
            edges {
                node {
                    id
                    title
                }
            }
        }
        posts: allSanityPost {
            edges {
                node {
                    id
                    title
                    slug {
                        current
                    }
                    categories {
                        id
                        title
                    }
                    postPreview: excerpt {
                        children {
                            text
                        }
                    }
                    poster: mainImage {
                        asset {
                            gatsbyImageData
                        }
                        alt
                    }
                }
            }
        }
    }
`;

const Blog = ({ data, pageContext }: any) => {
    const { t } = useTranslation();
    const { categories, posts, img } = data;

    const postCategories: DropdownItemProps[] = [
        { label: 'All', value: 'all' },
        ...categories.edges.map(({ node }: any) => ({ label: node.title, value: node.title })),
    ];
    const [selectedCategory, setSelectedCategory] = useState<DropdownItemProps>(postCategories[0]);
    const [categoryPosts, setCategoryPosts] = useState(posts.edges);

    const handleCategorySelect = (category: DropdownItemProps | null) => {
        if (!category) return;

        setSelectedCategory(category);
    };

    useEffect(() => {
        if (selectedCategory.value === 'all') {
            setCategoryPosts(posts.edges);
        } else {
            const filteredPosts = posts.edges.filter(({ node: post }: any) =>
                post.categories.find((category: any) => category.title === selectedCategory.value),
            );
            setCategoryPosts(filteredPosts);
        }
    }, [selectedCategory]);

    return (
        <>
            <SEO>
                <meta name="robots" content="noindex, follow" />
            </SEO>
            <Layout pageContext={pageContext}>
                <Box as="section" position="relative" pb={120}>
                    <BackgroundImage
                        fixed
                        style={{ minHeight: '100vh' }}
                        imageData={img.childImageSharp.gatsbyImageData}
                        overlay="vertical"
                    >
                        <GridParent>
                            <GridChild
                                gridColumn={{
                                    _: 'span 12',
                                    laptop: '3/ span 8',
                                    desktop: '4/span 6',
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection={{ _: 'column', laptopS: 'row' }}
                                    justifyContent="space-between"
                                    mt={96}
                                    mb={50}
                                >
                                    <Typography variant="h2" color="var(--white)">
                                        {t('CRYO BLOGS')}
                                    </Typography>
                                    <Box width="50%">
                                        <Dropdown
                                            hasBorder
                                            isSearchable={false}
                                            onChange={handleCategorySelect}
                                            options={postCategories}
                                            selected={selectedCategory}
                                            label="Select category"
                                            name="category"
                                            components={{
                                                DropdownIndicator: ArrowIcon,
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <List initialPosts={categoryPosts} />
                            </GridChild>
                        </GridParent>
                    </BackgroundImage>
                </Box>
            </Layout>
        </>
    );
};

export default Blog;
